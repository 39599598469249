.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .btn-play {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 9px;
    border-color: transparent transparent transparent #1F1F1F;
    cursor: pointer;
    margin-left: 2px;
  }
  
  .btn-stop {
    height: 10px;
    width: 8px;
    border-left: 2.5px solid #E7DB9D;
    border-right: 2.5px solid #E7DB9D;
    cursor: pointer;
  }

