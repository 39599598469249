@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "vazirmatn";
  src: url("./font/Vazirmatn-Regular.ttf");
}
@font-face {
  font-family: "schehrazade";
  src: url("./font/ScheherazadeNew-Regular.ttf");
}
.interface-grid{
  @apply grid-cols-4
}
.rm-audio-player-provider{
  @apply bg-none
}
.li-home{
  @apply leading-[19px] text-xs text-whiteA hover:text-cream no-underline flex pr-4 items-center justify-start h-[43px] w-[200px] 2xl:pr-0 2xl:w-14 2xl:text-base 2xl:leading-6 2xl:font-semibold
}
.li {
  @apply relative no-underline text-gray-500 flex md:justify-center gap-2 cursor-pointer my-4 px-1 items-center hover:text-gray-900 ;
}
/* react-musicPlayer */
.audio-lists-panel{
  @apply absolute left-8 bottom-20 origin-bottom-left
}
.react-jinke-music-player-mobile-toggle {
  @apply flex justify-center
}
.react-jinke-music-player-mobile-operation{
  @apply flex justify-center
}
.react-jinke-music-player-mobile-operation .items{
  @apply gap-x-20 min-[375px]:gap-x-32
}
/* react-musicPlayer */
.inp {
  @apply relative  w-full  rounded-md  mx-0  grid grid-cols-[7fr_1fr] items-center px-4 
}
/* audioplayer */
.btn-play-home {
  @apply w-0 h-0 border-y-[4.5px] border-r-0 border-l-[6.5px] border-transparent border-l-blackB ml-[2px] cursor-pointer 2xl:ml-1 rounded-sm  2xl:border-y-[15px] 2xl:border-l-[22px]
}
.btn-stop-home {
  @apply h-[7px] w-[6px] border-x-[2px] border-x-cream cursor-pointer 2xl:w-5 2xl:h-6 2xl:border-x-[7px]
}
.btn-container-home {
  @apply flex justify-center items-center w-[18px] h-[18px] flex-grow 2xl:w-full 2xl:h-full
}






body {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}


/* landing */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #1b1b1b inset !important;
  -webkit-text-fill-color: white !important;
}