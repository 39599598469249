* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  
  
  /* h1 {
    text-align: center;
    font-weight: 400;
    margin-bottom: 35px;
  } */
  
  .player-container {
    /* height: 40px;
    width: 360px; */
    /* padding: 0 10px; */
    /* background-color: #272727; */
    /* padding: 30px 50px; */
    /* border-radius: 10px; */
    /* box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.479); */
  }